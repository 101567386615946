<template>
<div>
  <v-dialog v-model="localDialog" style="border: 100px white solid" max-width="380px"  transition="dialog-top-transition">
    <v-card class="pa-7">
      <v-card-title class="d-flex justify-center align-center">
        <v-row  dense>
          <v-col class="py-0 mb-1" cols="12">
                <span class="icon-background">
                  <v-icon dense color="red" style="font-size: 30px" class="my-0">mdi-alert-outline</v-icon>
                </span>
          </v-col>
          <v-col cols="12">
            <span>{{ $t('deleteQuestion') }}</span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="text-center">{{ $t('questionTextForDelete') }}</v-card-text>
      <v-card-actions class="mb-2">
        <v-row dense>
          <v-col cols="12">
            <v-btn block depressed color="alert_red" class="text-color-white" @click="deleteHospitalConfirm">{{ $t('deleteField') }}</v-btn>
          </v-col>
          <v-col cols="12">
            <v-btn  outlined depressed color="main_black" block @click="closeDeleteDialog">{{ $t("cancel") }}</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>
<script>
import { showErrorAlert, showSuccessAlert, toggleHeaders } from '@/utils/utilities';
export default {
  name: 'OfficesDeleteDialog.vue',
  props: ['dialogDelete', 'officeId'],
  data () {
    return {
    localDialog: this.dialogDelete,
    localOfficeId: this.officeId,
    loading: false,
    };
  },
  computed: {

  },
  methods: {
    toggleHeaders,
    async getHospitals () {
      this.loading = true;
      await this.$store.dispatch('hospitals/getHospitals').then(() => {
        this.loading = false;
      });
      this.toggleHeaders();
    },
    closeDeleteDialog () {
      this.localDialog = false;
    },
    async deleteHospitalConfirm () {
      await this.$store.dispatch('hospitals/deleteHospital', this.localOfficeId).then(res => {
        if (res.data.resFlag) {
          showSuccessAlert(this.$t('success-operation'));
          this.getHospitals();
          this.closeDeleteDialog();
        } else {
          showErrorAlert(this.$t('failed-operation'));
        }
      });
    },
  },
  watch: {
    dialogDelete (val) {
      this.localDialog = val;
    },
    officeId (val) {
      this.localOfficeId = val;
    },
    localDialog (val) {
      this.$emit('update:dialogDelete', val);
    },
    localOfficeId (val) {
      this.$emit('update:officeId', val);
    },
  },
};
</script>
<style scoped>

</style>
